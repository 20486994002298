body {
    background-image: url(../img/white-waves.png);
    color: #111111;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
}

h1 {
    font-size: 1.5em;
    text-align: center;

    @media (min-width: 800px) {
        font-size: 2.5em;
    }
}

strong {
    font-size: 1.5em;
    vertical-align: middle;
}
