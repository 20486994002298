.container {
    margin: 0 auto;
    max-width: 800px;
    padding: 0 20px;

    @media (min-width: 800px) {
        padding: 0;
    }
}

.section {
    margin: 60px 0;
}
