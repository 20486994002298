body {
  color: #111;
  text-align: center;
  background-image: url("white-waves.c5c37c83.png");
  font-family: Courier New, Courier, monospace;
}

h1 {
  text-align: center;
  font-size: 1.5em;
}

@media (min-width: 800px) {
  h1 {
    font-size: 2.5em;
  }
}

strong {
  vertical-align: middle;
  font-size: 1.5em;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

@media (min-width: 800px) {
  .container {
    padding: 0;
  }
}

.section {
  margin: 60px 0;
}

.bio {
  margin: 0 auto;
}

.bio__image {
  width: 15rem;
  height: 15rem;
  border: .5rem solid #444535;
  border-radius: 100%;
}

.bio__number {
  font-size: 4em;
  font-weight: 700;
  display: block;
}

.bio__name {
  font-size: 2em;
  display: block;
}

.dataset {
  grid-template-columns: 1fr;
  gap: 20px;
  display: grid;
}

.dataset__item {
  background-color: #eee;
  border: 2px solid #ccc;
  align-items: center;
  gap: 5%;
  padding: 10px;
  display: flex;
}

.dataset__value {
  text-align: right;
  width: 50%;
  font-size: 1.8em;
  font-weight: 700;
}

@media (min-width: 800px) {
  .dataset__value {
    font-size: 6em;
  }
}

.dataset__value--small {
  font-size: 1.8em;
}

.dataset__label {
  text-align: left;
  width: 32.5%;
  font-size: 1.25em;
  font-weight: 400;
}

@media (min-width: 800px) {
  .dataset__label {
    font-size: 2em;
  }
}

/*# sourceMappingURL=index.14534002.css.map */
