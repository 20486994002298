.bio {
    margin: 0 auto;
}

.bio__image {
    width: 15rem;
    height: 15rem;
    border-radius: 100%;
    border: 0.5rem solid #444535;
}

.bio__number {
    font-size: 4em;
    font-weight: 700;
    display: block;
}

.bio__name {
    font-size: 2em;
    display: block;
}
